import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { RxChevronLeft } from 'react-icons/rx';
import useSWR from 'swr';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { FaRegTrashAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import toast from 'react-hot-toast';
import { serviceInstance } from '../../../lib/axios';
import useUser from '../../../hooks/useUser';
import DashboardLayout from '../../../components/layout/dashboardLayout';

Modal.setAppElement('#root');
export default function NotificationDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, _] = useSearchParams();
  const { user, selectedProfile } = useUser();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirming, setIsConfirming] = useState();

  const { data: notification, mutate } = useSWR(
    user?.api_key && selectedProfile?.time_zone
      ? [
          `/notification_detail_one`,
          selectedProfile.id_cliente,
          selectedProfile.time_zone,
          id,
        ]
      : null,
    async ([url, idCliente, timezone, id]) => {
      const response = await serviceInstance.post(
        url,
        {
          id_cliente: idCliente,
          timezone,
          id_notificacion: id,
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.user_client_message_detail;
    },
  );

  const deleteNotification = async () => {
    try {
      await serviceInstance.post(
        '/notifications_delete',
        {
          id_cliente: selectedProfile.id_cliente,
          timezone: selectedProfile.time_zone,
          id_notificacion: [id],
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      toast.success('Notificaciones eliminadas con éxito');
    } catch (error) {
      toast.error('Error al eliminar las notificaciones');
    } finally {
      mutate();
      setIsDeleting(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-8">
        <div className="flex flex-row gap-2 w-full justify-between">
          <button
            className="flex flex-row items-center gap-2 text-white"
            onClick={() => navigate(-1)}
            tabIndex="0"
          >
            <RxChevronLeft className="size-6 text-4xl" />
          </button>
          <div className="w-full flex flex-row justify-between gap-2 items-center">
            <div className="flex flex-row gap-4 w-full text-white items-center">
              <ReactSVG src={`/svgs/${searchParams.get('icon')}`} alt="icono" />
              <div className="flex flex-col">
                <h1 className="text-3xl font-bold w-[10rem] truncate">
                  {searchParams.get('name')}
                </h1>
                <span>Mas detalles</span>
              </div>
            </div>
            <div className="flex w-full items-center text-white p-4 justify-end">
              <button
                className="bg-blue-900 p-4 rounded-2xl"
                onClick={() => setIsDeleting(true)}
              >
                <FaRegTrashAlt size={24} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 text-white">
        {notification?.map((item) => (
          <div key={item.id_notificacion}>
            <div className="flex flex-col gap-2 bg-gray-800 p-6 rounded-md">
              <div className="flex flex-row gap-4 items-center">
                <div className="flex flex-col">
                  <span>{item.mensaje}</span>
                </div>
              </div>
              <span className="ml-auto text-xs text-[#425E79]">
                {format(item.created_at, 'dd/MM/yyyy hh:mm a')}
              </span>
            </div>

            {item.datos !== 'NULL' && (
              <div className="mt-8">
                <button
                  className="bg-blue-800 px-8 py-3 rounded-2xl text-white w-full"
                  onClick={() => {
                    setIsConfirming(atob(item.datos));
                  }}
                >
                  Confirmación
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      <Modal
        isOpen={isDeleting}
        onRequestClose={() => setIsDeleting(false)}
        style={{
          content: {
            position: 'relative',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '90%',

            paddingLeft: '1rem',
            paddingRight: '1rem',
            backgroundColor: '#1a202c',
            color: 'white',
            border: 'none',
            transform: 'translate(-50%, -50%)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <div className="absolute right-0 top-0 p-2">
          <button
            onClick={() => {
              setIsDeleting(false);
            }}
            className="border rounded-full bg-gray-700 size-4 mt-2"
          >
            <RiCloseLine color="white" />
          </button>
        </div>
        <h2 className="pb-2 border-b">Eliminar notificacion</h2>
        <div className="flex flex-col gap-8 pt-2 overflow-y-auto">
          <p>
            Estas seguro en eliminar la notificación, recuerda que una vez
            haciendo esta acción, no podrás volver a ver esta información .
          </p>
          <div className="flex flex-row gap-4 justify-center">
            <button
              className="bg-blue-800 px-8 py-3 rounded-2xl"
              onClick={deleteNotification}
            >
              Borrar
            </button>
            <button
              className="bg-transparent border border-green-400 text-green-400 px-8 py-3 rounded-2xl"
              onClick={() => setIsDeleting(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isConfirming}
        onRequestClose={() => setIsConfirming(undefined)}
        style={{
          content: {
            position: 'relative',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '90%',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            backgroundColor: '#1a202c',
            color: 'white',
            border: 'none',
            transform: 'translate(-50%, -50%)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <div className="absolute right-0 top-0 p-2">
          <button
            onClick={() => {
              setIsConfirming(false);
            }}
            className="border rounded-full bg-gray-700 size-4 mt-2"
          >
            <RiCloseLine color="white" />
          </button>
        </div>
        <h2 className="pb-2 border-b">Confirmación de la notificación</h2>
        <div className="flex flex-col gap-8 pt-2 overflow-y-auto bg-white">
          <iframe
            src={isConfirming}
            title="Decoded URL"
            width="100%"
            height="600px"
          />
        </div>
      </Modal>
    </DashboardLayout>
  );
}
