import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { useLocalStorage } from '@uidotdev/usehooks';
import { serviceInstance } from '../lib/axios';

export default function useUser() {
  const [user, setUser] = useState(null);
  const [value, setValue] = useLocalStorage('pwa-version');

  const localStorageUser = JSON.parse(localStorage.getItem('user'));
  const localStorageSelectedProfile = JSON.parse(
    localStorage.getItem('selectedProfile'),
  );
  const [selectedProfile, setSelectedProfile] = useState(null);

  const { data, error, mutate } = useSWR('/user_info', async (key) => {
    if (!localStorageUser) {
      return null;
    }
    const response = await serviceInstance.post(
      key,
      {},
      {
        headers: {
          Authorization: localStorageUser.api_key,
        },
      },
    );
    return response.data;
  });

  useEffect(() => {
    if (data?.data) {
      setUser(data.data);
      const profile = localStorageSelectedProfile || data.data?.[0];
      setSelectedProfile(profile);
      localStorage.setItem('selectedProfile', JSON.stringify(profile));
    }
  }, [data?.data]);

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    if (data && value !== data?.pwa_version) {
      setValue(data?.pwa_version);
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        window.location.reload(true);
      }
    }
  }, [value, data]);

  useEffect(() => {
    if (selectedProfile) {
      localStorage.setItem('selectedProfile', JSON.stringify(selectedProfile));
    }
  }, [selectedProfile]);

  return {
    user: {
      profiles: user,
      ...localStorageUser,
    },
    selectedProfile,
    setSelectedProfile,
    error,
  };
}
