import React, { useEffect } from 'react';
import useSWR from 'swr';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { formatDistanceToNowStrict, isValid } from 'date-fns';
import { es } from 'date-fns/locale';
import { RxChevronLeft } from 'react-icons/rx';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { serviceInstance } from '../../../lib/axios';
import useUser from '../../../hooks/useUser';
import DashboardLayout from '../../../components/layout/dashboardLayout';
import useLocationConfig from '../../../hooks/useLocationConfig';
import Spinner from '../../../components/loading';

const StatusDetails = () => {
  const navigate = useNavigate();
  const configData = useLocationConfig();
  const { id } = useParams();
  const [searchParams, _] = useSearchParams();
  const { user, selectedProfile } = useUser();
  const {
    data: details,
    mutate,
    isValidating,
  } = useSWR(
    user?.api_key && selectedProfile?.time_zone
      ? `/singleservice_status_initial`
      : null,
    async (key) => {
      const response = await serviceInstance.post(
        key,
        {
          id_cliente: selectedProfile?.id_cliente,
          timezone: selectedProfile?.time_zone,
          id_test: id,
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.initial_status_data;
    },
  );

  useEffect(() => {
    mutate();
  }, []);

  const configLists = (id) => {
    const config = [...new Set(configData)].find(
      (item) => item.id_ubicacion === id,
    );
    return config;
  };

  const detailsList = (id) => {
    const detail = details?.filter((item) => item.id_ubicacion === id);
    return detail;
  };

  const uniqueDetails = details && [
    ...new Map(details.map((item) => [item.id_ubicacion, item])).values(),
  ];

  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-8">
        <div className="flex flex-row gap-2">
          <button
            className="flex flex-row items-center gap-2 text-white"
            onClick={() => navigate(-1)}
            tabIndex="0"
          >
            <RxChevronLeft className="size-6 text-4xl" />
          </button>
          <div className="text-white">
            <h1 className="text-3xl font-bold">{searchParams.get('name')}</h1>
            <span>Estado actual</span>
          </div>
        </div>
        <div className="flex items-center">
          <div
            className={classNames('p-2 min-h-12 min-w-12 max-w-12 max-h-12', {
              'bg-green-600':
                parseInt(searchParams.get('status_code'), 10) === 0,
              'bg-yellow-400':
                parseInt(searchParams.get('status_code'), 10) === 1,
              'bg-red-600':
                parseInt(searchParams.get('status_code'), 10) === 2 &&
                searchParams.get('en_incidente') === 'true',
              'bg-orange-400':
                parseInt(searchParams.get('status_code'), 10) === 2 &&
                searchParams.get('en_incidente') !== 'true',
            })}
            style={{
              position: 'relative',
              overflow: 'hidden',
              width: '48px',
              height: '48px',
              clipPath:
                'polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)',
            }}
          >
            <ReactSVG
              src={`/svgs/${+searchParams.get('monitor_family') === 1 ? 'robotBlack' : 'privalyticsBlack'}.svg`}
            />
            {searchParams.get('en_mantenimiento') && (
              <div className="absolute top-0 left-0">
                <ReactSVG src="svgs/mantenimiento.svg" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="space-y-4 h-[70vh] overflow-y-auto">
        {uniqueDetails?.length && !isValidating ? (
          uniqueDetails.map((detail) => (
            <div
              key={detail.timestamp}
              role="button"
              tabIndex="0"
              onClick={() =>
                navigate(
                  `/dashboard/status/${id}/incident-details?name=${configLists(detail.id_ubicacion)?.nombre}&id=${detail.id_ubicacion}&country=${configLists(detail.id_ubicacion)?.pais.toLowerCase()}&prev=${searchParams.get('name')}`,
                )
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  navigate(
                    `/dashboard/status/${id}/incident-details?name=${configLists(detail.id_ubicacion)?.nombre}&id=${detail.id_ubicacion}&country=${configLists(detail.id_ubicacion)?.pais.toLowerCase()}&prev=${searchParams.get('name')}`,
                  );
                }
              }}
              className={classNames(
                'bg-gray-800 p-4 rounded-lg flex justify-between items-center border-l-4 border-green-500',
                {
                  'border-green-500': detail.overall_location_status === 'ok',
                  'border-yellow-500':
                    detail.overall_location_status === 'degraded',
                  'border-red-500': detail.overall_location_status === 'failed',
                },
              )}
            >
              <div className="flex items-center space-x-3 w-full">
                <div className="flex flex-col gap-2 w-full">
                  <p className="text-white text-2xl font-bold mb-2">
                    {configLists(detail.id_ubicacion)?.nombre}
                  </p>
                  {detailsList(detail.id_ubicacion)?.map((item, index) => (
                    <div
                      className="flex flex-row gap-2 text-gray-400 text-sm w-[15rem] truncate"
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <ReactSVG
                        // eslint-disable-next-line no-nested-ternary
                        src={`/svgs/${item.step_success === 'true' ? 'check' : 'close'}.svg`}
                      />
                      <p>{item.label}</p>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col gap-y-6 w-24 items-center">
                  <img
                    src={`https://api.realuptime.net/flags/${configLists(detail.id_ubicacion)?.pais.toLowerCase()}.png`}
                    alt="flag"
                    className="h-8 w-12"
                  />
                  <p className="text-sm text-white">
                    {isValid(detail.updated) &&
                      formatDistanceToNowStrict(new Date(detail.updated), {
                        addSuffix: true,
                        locale: es,
                      })
                        .replace(' minutos', 'm')
                        .replace(' horas', 'h')
                        .replace(' días', 'd')
                        .replace(' meses', 'mo')
                        .replace(' años', 'y')
                        .replace(' minuto', 'm')
                        .replace(' hora', 'h')
                        .replace(' día', 'd')
                        .replace(' mes', 'mo')
                        .replace(' año', 'y')
                        .replace('en', 'hace ')}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Spinner />
        )}
      </div>
    </DashboardLayout>
  );
};

export default StatusDetails;
