import React, { useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PiExclamationMark } from 'react-icons/pi';
import classNames from 'classnames';
import { RxChevronLeft } from 'react-icons/rx';
import { serviceInstance } from '../../../lib/axios';
import useUser from '../../../hooks/useUser';
import DashboardLayout from '../../../components/layout/dashboardLayout';
import Spinner from '../../../components/loading';

const IncidentBreakdown = () => {
  const navigate = useNavigate();
  const { user, selectedProfile } = useUser();
  const [searchParams, _] = useSearchParams();

  const { id } = useParams();
  const {
    data: details,
    mutate: mutateDetails,
    isValidating: isSingleValidating,
  } = useSWR(
    user?.api_key && selectedProfile ? `/singleservice_status_initial` : null,
    async (key) => {
      const response = await serviceInstance.post(
        key,
        {
          id_cliente: selectedProfile?.id_cliente,
          timezone: selectedProfile?.time_zone,
          id_test: id,
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.initial_status_data;
    },
  );

  const {
    data: singleServiceLocationData,
    mutate: mutateSingleService,
    isValidating,
  } = useSWR(
    user?.api_key && selectedProfile ? `/singleservice_status_location` : null,
    async (key) => {
      const response = await serviceInstance.post(
        key,
        {
          id_cliente: selectedProfile?.id_cliente,
          timezone: selectedProfile?.time_zone,
          id_test: id,
          id_ubicacion: parseInt(searchParams.get('id'), 10),
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data;
    },
  );

  useEffect(() => {
    mutateSingleService();
    mutateDetails();
  }, [selectedProfile]);

  const mappedData = details && details[searchParams.get('index')];
  const singleServiceLocation = () => {
    const indexes = singleServiceLocationData?.response_status_data?.map(
      (item) => ({
        secuencia: parseInt(item.secuencia, 10),
        label: item.label,
        timestamp: item.timestamp,
        responsemessage: item.responsemessage,
      }),
    );

    const metricStatusDataGroupedBySequence = indexes?.map((index) => {
      const data = singleServiceLocationData?.metric_status_data?.filter(
        (item) => parseInt(item.secuencia, 10) === index.secuencia,
      );

      return data.map((item) => ({
        ...item,
        label: index.label,
        timestamp: index.timestamp,
        responsemessage: index.responsemessage,
      }));
    });

    return metricStatusDataGroupedBySequence?.[searchParams.get('index')];
  };

  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-8">
        <div className="flex flex-row gap-2">
          <button
            className="flex flex-row items-center gap-2 text-white"
            onClick={() => navigate(-1)}
            tabIndex="0"
          >
            <RxChevronLeft className="size-6 text-4xl" />
          </button>
          <div className="text-white w-[18rem] truncate">
            <h1 className="text-3xl font-bold">{mappedData?.label}</h1>
            <span>{searchParams.get('prev')}</span>
          </div>
        </div>
        <div className="flex items-center">
          <div className="bg-gray-700 rounded-full flex items-center justify-center">
            <img
              src={`https://api.realuptime.net/flags/${searchParams.get('country')}.png`}
              alt="flag"
              className="h-8 w-12"
            />
          </div>
        </div>
      </div>

      {/* Metrics Section */}
      <div className="mb-8 text-white">
        {/* Metric Rows */}
        <div className="h-[70vh] overflow-y-auto">
          {singleServiceLocation()?.length &&
          !isSingleValidating &&
          !isValidating ? (
            singleServiceLocation()?.map((metric, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="flex flex-col items-center w-full bg-gray-800 rounded-lg p-4"
              >
                <div key={metric.tags} className="flex flex-col w-full">
                  {index === 0 && (
                    <div>
                      <div className="flex flex-row justify-between text-white pb-4 border-b border-b-gray-600 mb-4">
                        <h1 className="text-xl">{metric.label}</h1>
                        <span className="text-sm text-gray-400">
                          {metric.timestamp}
                        </span>
                      </div>
                      <div className="flex justify-between mb-4">
                        <h2 className="text-lg font-bold">Metricas</h2>
                      </div>
                    </div>
                  )}
                  <div
                    className={classNames(
                      'flex flex-col justify-between w-full border-l-4 p-4',
                      {
                        'border-l-green-600': metric.status === 0,
                        'border-l-yellow-400': metric.status === 1,
                        'border-l-red-600': metric.status === 2,
                        'border-l-blue-600': metric.status === 3,
                      },
                    )}
                  >
                    <div className="flex flex-row gap-2">
                      <p className="text-gray-500">Tags:</p>
                      <p>{metric.tags}</p>
                    </div>
                    <div className="flex flex-row gap-2">
                      <p className="text-gray-500">Valor:</p>
                      <p>{metric.valor}</p>
                    </div>
                    <div className="flex flex-row gap-2">
                      <p className="text-gray-500">Info:</p>
                      <p>{metric.razon || 'N/A'}</p>
                    </div>
                  </div>
                  <i className="fas fa-info-circle text-gray-400" />
                  {index === metric.length - 1 && (
                    <p className="text-start text-gray-500 mt-4">
                      {metric?.responsemessage}
                    </p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default IncidentBreakdown;
